import Head from 'next/head'
import Auth from '@/components/login/Auth'

const Home = () => {
    return (
        <>
            <Head>
                <title>{`Login | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <div>
                <Auth />
            </div>
        </>
    )
}
Home.guest = true
export default Home
