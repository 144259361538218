import { Formik, Form } from 'formik'
import { useCallback, useState, useEffect } from 'react'

import Button from '@/components/base/Button'

const Resend = ({ initialState, email, password }) => {
    const [timer, setTimer] = useState(30)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        if (!timer) return

        const intervalId = setInterval(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [timer])

    const onSubmit = useCallback(
        async (formData, { setSubmitting }) => {
            setTimer(30)
            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const data = await response.json()

            if (!response.ok) {
                setTimer(0)
                setErrorMessage(data?.message)
            }
            setSubmitting(false)
        },
        [email, password]
    )

    useEffect(() => {
        if (errorMessage) {
            const delay = setTimeout(() => {
                setErrorMessage(null)
            }, 2000)
            return () => clearTimeout(delay)
        }
    }, [setErrorMessage, errorMessage])

    return (
        <Formik
            initialValues={initialState}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ submitForm }) => {
                return (
                    <Form noValidate>
                        <div className="mt-6 text-center align-middle ipad:mt-2 mobile:mt-5">
                            <p className="text-sm font-normal mb-1 text-interface-600">
                                Have not received OTP code yet?
                            </p>
                            {!timer && (
                                <Button
                                    id="submit"
                                    type="submit"
                                    variant="normalizeLink"
                                    text="Resend"
                                    size="xs"
                                    semiBold
                                    modification="inline-block p-0 hover:underline"
                                    onClick={submitForm}
                                />
                            )}

                            {!!timer && (
                                <div className="mt-[6px] flex justify-center">
                                    <p className="text-interface-600">
                                        Resend in:
                                    </p>
                                    <div className="ml-2 font-bold text-primary-500">
                                        <span>
                                            {'00'}:
                                            {timer?.toString()?.length === 1
                                                ? `0${timer}`
                                                : timer}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default Resend
