import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import Login from '@/components/login/AuthLogin'
import OTPPage from '@/pages/otp/index'

import { useSession } from '@/contexts/Session'

const Auth = () => {
    const router = useRouter()
    const query = router.query
    const {
        authToken: { isNotRequiredOtp },
    } = useSession()

    const [emailAddress, setEmailAddress] = useState(query?.email || null)
    const [password, setPassword] = useState(null)

    const [remember, setRemember] = useState(false)
    const [redirectToLogin, setRedirectToLogin] = useState(true)

    const initialValues = useMemo(
        () => ({
            email: emailAddress || '',
            password: '',
            verificationCode: '',
            remember: remember || false,
        }),
        [remember, emailAddress]
    )

    return (
        <div>
            {(!emailAddress || redirectToLogin) && (
                <div>
                    <Login
                        initialValues={initialValues}
                        setEmailAddress={setEmailAddress}
                        setRemember={setRemember}
                        setRedirectToLogin={setRedirectToLogin}
                        setPassword={setPassword}
                        isNotRequiredOtp={isNotRequiredOtp}
                    />
                </div>
            )}
            {(isNotRequiredOtp === 'false' || !isNotRequiredOtp) &&
                emailAddress &&
                !redirectToLogin && (
                    <div>
                        <OTPPage
                            initialValues={initialValues}
                            emailAddress={emailAddress}
                            remember={remember}
                            password={password}
                        />
                    </div>
                )}
        </div>
    )
}

export default Auth
