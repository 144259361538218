import { useCallback, useEffect, useState } from 'react'
import Head from 'next/head'

import keys from 'lodash/keys'
import { Formik, Form } from 'formik'

import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'
import Alert from '@/components/base/Alert'
import Resend from '@/components/login/Resend'

import { otp } from '@/validations/login'

import { useSession } from '@/contexts/Session'

import styles from '@/styles/Auth.module.css'

const OTPPage = ({ initialValues, emailAddress, remember, password }) => {
    const { setAuthToken } = useSession()

    const [errorMessage, setErrorMessage] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSubmit = useCallback(
        async ({ verificationCode }, { setSubmitting }) => {
            setDisabled(true)
            setLoading(true)
            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/verify-code`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailAddress,
                    verificationCode: verificationCode?.toString(),
                    remember,
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const responseData = await response.json()

            if (response.ok) {
                const timerId = setTimeout(() => {
                    setAuthToken('id', responseData?.userId, {})
                    setAuthToken('isNotRequiredOtp', true, {})
                    setAuthToken(
                        'accessToken',
                        responseData?.data?.accessToken,
                        {
                            path: '/',
                            sameSite: true,
                        }
                    )
                    setAuthToken(
                        'refreshToken',
                        responseData?.data?.refreshToken,
                        {
                            path: '/',
                            sameSite: true,
                        }
                    )
                }, 2000)

                return () => clearTimeout(timerId)
            } else {
                setErrorMessage(responseData?.message)
            }

            setSubmitting(false)
            setDisabled(false)
            setLoading(false)
        },
        [setAuthToken, emailAddress, remember]
    )

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage(null)
            }, 3000)
            return () => clearTimeout(timer)
        }
    }, [setErrorMessage, errorMessage])
    return (
        <>
            <Head>
                <title>{`OTP Verification | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <div>
                <h2 className={styles.heading}>Two Factor Authentication</h2>
                <h3 className={styles.tagline}>
                    A 6-digit verification code has been sent to your email.{' '}
                    <br />
                    This OTP code will be valid for 15 minutes.
                </h3>

                {/* Form  */}
                <div>
                    <Formik
                        validationSchema={otp}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {({ errors, touched, submitForm, isSubmitting }) => {
                            const firstKey = keys(errors)[0]
                            const showError =
                                !!errors[firstKey] && !!touched[firstKey]
                            return (
                                <Form
                                    noValidate
                                    onKeyUp={({ target: { name, value } }) => {
                                        setErrorMessage(null)
                                        if (
                                            name === 'verificationCode' &&
                                            value?.toString()?.length >= 6
                                        ) {
                                            submitForm()
                                        }
                                    }}
                                >
                                    <div>
                                        <TextBox
                                            id="otp-code"
                                            name="verificationCode"
                                            type="number"
                                            label="OTP Code"
                                            placeholder="Enter your 6-digit verification code"
                                            size="md"
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            id="submit"
                                            variant="primary"
                                            text="Sign In"
                                            modification="w-full"
                                            disabled={disabled} // Add this line to disable the button during form submission
                                        />
                                    </div>
                                    {(errorMessage || showError) && (
                                        <div className="mt-6">
                                            <Alert
                                                variant="error"
                                                message={
                                                    errorMessage
                                                        ? errorMessage
                                                        : errors[firstKey]
                                                }
                                                noSpace
                                                icon="info"
                                                iconStyle="text-danger-500 pr-2 text-xs"
                                                isSubmitting={isSubmitting}
                                            />
                                        </div>
                                    )}
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
            {!loading && (
                <Resend
                    email={emailAddress}
                    initialState={initialValues}
                    password={password}
                />
            )}
        </>
    )
}

OTPPage.guest = true
export default OTPPage
